function isVimeoLink(source) {
  // Regular expression pattern to match Vimeo video URLs
  const vimeoPattern = /^(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/(video\/)?(\d+)(\/[^\s]*)?$/;

  const id = source.match(vimeoPattern)?.[5];

  if (id) {
    return `https://player.vimeo.com/video/${id}`;
  }

  if (!isNaN(source)) {
    return `https://player.vimeo.com/video/${source}`;
  }
  return vimeoPattern.test(source);
}

export default isVimeoLink;

import { useEffect, useState } from "react";
import camelToKebabCase from "../utils/camelToKebabCase";

const Style = ({ styles = {}, uniqueId }) => {
  // console.log({ uniqueId });
  const [CSS, setCSS] = useState(null);
  useEffect(() => {
    let css = "";
    if (typeof styles === "object") {
      Object.keys(styles).map((key) => {
        if (typeof styles[key] === "object") {
          let itemCSS = "";
          Object.keys(styles[key]).map((key2) => {
            itemCSS += `${camelToKebabCase(key2)}: ${styles[key][key2]};`;
          });
          css += `#${uniqueId} ${[".", "#"].includes(key[0]) ? "" : "."}${key}{${itemCSS}} `;
        }
      });
    }
    setCSS(css);
  }, [styles, uniqueId]);

  return <style dangerouslySetInnerHTML={{ __html: CSS }}></style>;
};

export default Style;

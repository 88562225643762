function isYoutubeURL(input) {
  // Regular expressions to check for YouTube URLs and extract the video ID
  const youtubeURLRegex = /^(?:(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?([a-zA-Z0-9_-]+))$/;
  const youtubeIDRegex = /^[a-zA-Z0-9_-]{11}$/;

  if (youtubeURLRegex.test(input)) {
    return input;
  } else if (youtubeIDRegex.test(input)) {
    return `https://www.youtube.com/watch?v=${input}`;
  } else {
    return false;
  }
}

export default isYoutubeURL;

import { useEffect } from "react";
import { __ } from "@wordpress/i18n";
import "./style.scss";

const PopupOpener = ({ setOpen, popup, poster }) => {
  const { align, enabled, btnStyle, hasBtn, selector, type, btnText } = popup;
  const { padding, ...popupBtnStyle } = btnStyle || {};

  useEffect(() => {
    if (type === "button" && hasBtn && selector && enabled) {
      document.querySelector([".", "#", "["].includes(selector) ? `${selector}` : `.${selector}`)?.addEventListener("click", () => {
        setOpen(true);
      });
    }
  }, []);

  return (
    <>
      {enabled && (
        <div className={type === "button" ? `flex justify-${align}` : ""}>
          {!hasBtn || type === "poster" ? (
            <>
              {type === "button" ? (
                <button className="popup_opener" onClick={() => setOpen(true)} style={{ ...popupBtnStyle, padding: Object.values(padding).join(" ") }}>
                  {btnText}
                </button>
              ) : (
                <>
                  {poster ? (
                    <div className="popup_opener">
                      <svg aria-hidden="true" focusable="false" className="popup_play" onClick={() => setOpen(true)}>
                        <use xlinkHref="#plyr-play"></use>
                      </svg>
                      <img src={poster} width="100%" />
                    </div>
                  ) : (
                    <h3>Poster missing</h3>
                  )}
                </>
              )}
            </>
          ) : (
            <></>
          )}

          <span onClick={() => setOpen(false)} className="close">
            &times;
          </span>
        </div>
      )}
    </>
  );
};

export default PopupOpener;

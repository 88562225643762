import "./style.scss";

const Overlay = ({ enabled, items }) => {
  if (!enabled) {
    return <></>;
  }
  return (
    <>
      {items.map((item, index) => {
        return (
          <>
            <style>
              {`
              .overlay_item_${index} p{
                padding: 5px 12px;
              }
              .overlay_item_${index} p,
              .overlay_item_${index} img,
              .overlay_item_${index} a {
              color: ${item.color};
              font-size: ${item.fontSize};
              background-color: ${item.backgroundColor};
              border-radius: 3px px;
              opacity: ${item.opacity}
            }
            .overlay_item_${index} a:hover,
            .overlay_item_${index} p:hover {
              color: ${item.hoverColor};
            }
            `.replace(/\s+/g, " ")}
            </style>
            <div key={index} className={`h5vp_overlay ${item.position} overlay_item_${index}`}>
              {item.type === "text" ? (
                <p>
                  {item.link ? (
                    <a href={item.link} rel="noreferrer" target="_blank">
                      {item.text}
                    </a>
                  ) : (
                    <>{item.text}</>
                  )}
                </p>
              ) : item.link ? (
                <a className="background-transparent" href={item.link} rel="noreferrer" target="_blank">
                  <img src={item.logo} />
                </a>
              ) : (
                <img src={item.logo} />
              )}
            </div>
          </>
        );
      })}
    </>
  );
};

export default Overlay;

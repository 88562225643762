import { useEffect, useState, useRef } from "react";

import "./style.scss";
import "./../../public/css/h5vp.css";
import MyPlayer, { extractFileNamesWithoutExtension } from "../public/MyPlayer";
import Overlay from "./Overlay/Overlay";
import PopupOpener from "./PopupOpener/PopupOpener";
import Video from "./Video";
import { isYoutubeURL, useWPAjax } from "../../../wp-utils/v1";
import Youtube from "./Youtube";
import isVimeoLink from "../utils/isVimeoLink";
import EndScreen from "./EndScreen/EndScreen";
import Style from "./Style";
import Vimeo from "./Vimeo";
import generateUniqueID from "../../../wp-utils/v1/generateUniqueId";

/**
 *
 * @param {attributes} props
 * @param {nonce} props
 * @returns
 */

const VideoPlayer = ({ attributes, nonce = window.h5vpBlock?.nonce }) => {
  const { additionalCSS, additionalID, source, poster, qualities, captions, options, features, uniqueId, styles } = attributes;
  window.attributes = attributes;
  const { popup, overlay, endScreen, hideYoutubeUI } = features;
  const [player, setPlayer] = useState(null);
  const [open, setOpen] = useState(false);
  const [videoSource, setVideoSource] = useState("");
  const { autoplay, muted, loop } = options;
  const { data: isPremium } = useWPAjax("pipe_handler", { nonce });
  const { data: videoId, refetch } = useWPAjax("h5vp_ajax_handler", { nonce, method: "create", model: "Video" }, true);

  const playerRef = useRef(null);

  useEffect(() => {
    if (open) {
      player?.player.play();
    } else {
      player?.player.pause();
    }
  }, [open]);

  useEffect(() => {
    playerRef.media = playerRef.current?.querySelector("#player");
  }, [playerRef]);

  //initialize player
  useEffect(() => {
    if (player) {
      player.destroy();
    }

    setTimeout(() => {
      const media = playerRef.current?.querySelector("#player");
      if (media && typeof media.plyr !== "object") {
        console.log("initialize");
        setPlayer(new MyPlayer(media, options, features));
      }
    }, 30);
  }, [options, features, videoSource, poster]);

  useEffect(() => {
    if (player && !player?.nonce) {
      player.setNonce(nonce);
    }
    if (player && !player?.videoId) {
      player.setVideoId(videoId);
    }

    // if (player) {
    //   player.player.on("ready", () => {
    //     const placeholder = playerRef.current?.querySelector(".h5vp_lp");
    //     if (placeholder) {
    //       placeholder.style.display = "none";
    //     }
    //   });
    // }
  }, [player]);

  useEffect(() => {
    if (player) {
      player.setIsPremium(isPremium);
    }
  }, [isPremium]);

  useEffect(() => {
    if (!videoSource) {
      if (isYoutubeURL(source)) {
        setVideoSource("youtube");
      } else if (isVimeoLink(source)) {
        setVideoSource("vimeo");
      } else {
        setVideoSource("self-hosted");
      }
    }
  }, [source]);

  useEffect(() => {
    if (source && videoSource) {
      refetch({ src: source, type: videoSource, title: extractFileNamesWithoutExtension(source) });
    }
  }, [source, videoSource]);

  // useEffect(() => {
  //   console.log({ error, data });
  // }, [isLoading, data, error]);

  return (
    <div ref={playerRef} id={uniqueId} className={`h5vp_player_temp  ${popup.enabled ? "h5vp_popup_enabled" : ""} ${open ? "h5vp_popup_open" : ""}`}>
      <Style styles={styles} uniqueId={uniqueId} />
      <style>{additionalCSS}</style>

      {isPremium && <PopupOpener setOpen={setOpen} popup={popup} poster={poster} />}

      <div className={`plyr_wrapper ${hideYoutubeUI ? "hideUI" : ""}`} id={additionalID} data-unique-id={generateUniqueID(5)}>
        {isPremium && (
          <>
            {videoSource === "vimeo" && <Vimeo source={isVimeoLink(source)} />}
            {videoSource === "youtube" && <Youtube source={isYoutubeURL(source)} />}
          </>
        )}
        {videoSource === "self-hosted" && (
          <Video
            qualities={qualities}
            captions={captions}
            source={source}
            poster={poster}
            autoPlay={autoplay}
            muted={muted}
            loop={loop?.active}
            src={source}
            preload={options.preload}
            isPremium={isPremium}
            playsInline={options.playsinline}
          />
        )}
        {isPremium && (
          <>
            <EndScreen endScreen={endScreen} player={player} />
            <Overlay {...overlay} player={player?.player} />
          </>
        )}
      </div>
      {/* <div className="h5vp_lp" style={{ width: styles.plyr_wrapper }}>
        <svg xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="0 0 384 512">
          <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
        </svg>
        {[...Array(50)].map((index) => (
          <div key={index} className="bar bar-1"></div>
        ))}
      </div> */}
      {/* <h3>working fine</h3> */}
    </div>
  );
};

export default VideoPlayer;

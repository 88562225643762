import React from "react";
import { createRoot } from "react-dom";

import VideoPlayer from "../components/VideoPlayer";
import { jsonParse } from "./../../../wp-utils/v1";

document.addEventListener("DOMContentLoaded", function () {
  renderVideo();
});

export const FrontEnd = (props) => {
  console.log(props);
  return <VideoPlayer {...props} />;
};

window.addEventListener("elementor/frontend/init", function () {
  elementorFrontend.hooks.addAction("frontend/element_ready/H5VPPlayer.default", function (scope, $) {
    renderVideo();
  });
});

export const renderVideo = () => {
  const players = document.querySelectorAll(".html5_video_players");

  players?.forEach((item) => {
    const attributes = jsonParse(item.dataset.attributes);
    item.removeAttribute("data-attributes"); // remove
    item.removeAttribute("data-data"); // remove

    if (!attributes) {
      return false;
    }
    const nonce = item.dataset.nonce;
    if (typeof attributes === "object" && item) {
      const root = createRoot(item);
      root.render(<FrontEnd attributes={attributes} nonce={nonce} />);
    }
  });
};

import { useEffect, useRef } from "react";
import "./style.scss";

const EndScreen = ({ endScreen, player }) => {
  const { enabled, text, btnText, btnLink } = endScreen;

  const endScreenRef = useRef(null);

  useEffect(() => {
    player?.player?.on("ended", () => {
      console.log("ended");
      if (enabled) {
        player.player.stop();
        endScreenRef.current.style.display = "block";
        console.log("workign");
      }
    });
  }, [player]);

  const handleClose = () => {
    endScreenRef.current.style.display = "none";
  };

  return (
    <>
      {enabled && (
        <>
          <div ref={endScreenRef} className="h5vp_end_screen">
            <span className="close" onClick={handleClose}>
              &times;
            </span>
            <div className="content">
              <p>{text}</p>
              {btnText && (
                <a target="_blank" rel="noreferrer" href={btnLink}>
                  {btnText}
                </a>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EndScreen;
